<template>
  <div>
    <router-view></router-view>    
  </div>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>

<style scoped>
</style>